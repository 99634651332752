import { useEffect, useState } from "react";

import { useRouter } from "next/router";

export const useExtractQueryParam = (
  queryParamName: string
): [queryParamValue: string | string[] | null] => {
  // This hook extracts the specified query param from the URL
  // and resets the URL to remove the query param
  const { query, replace, pathname } = useRouter();
  const [queryParam, setQueryParam] = useState<string | string[] | null>(null);

  useEffect(() => {
    const queryParamValue = query[queryParamName];

    if (queryParamValue) {
      setQueryParam(queryParamValue);
      // Remove the query param from the URL
      // This will remove all query params, not just queryParamName
      replace(pathname, undefined, { shallow: true });
    }
  }, [query, replace, pathname, queryParamName]);

  return [queryParam];
};
