const MicrosoftIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3376_8527)">
        <path
          d="M11.8732 11.8796H0V0.00634766H11.8732V11.8796Z"
          fill="#F1511B"
        />
        <path
          d="M24.9823 11.8796H13.1094V0.00634766H24.9823V11.8796Z"
          fill="#80CC28"
        />
        <path d="M11.8729 24.9937H0V13.1206H11.8729V24.9937Z" fill="#00ADEF" />
        <path
          d="M24.9823 24.9937H13.1094V13.1206H24.9823V24.9937Z"
          fill="#FBBC09"
        />
      </g>
      <defs>
        <clipPath id="clip0_3376_8527">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftIcon;
