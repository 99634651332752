import React, { ReactNode, useEffect } from "react";

import { GetStaticProps } from "next";
import Link from "next/dist/client/link";

import { IconArrowRight } from "@tabler/icons";

import { useUIContext } from "contexts/UIContext";
import { useUserContext } from "contexts/UserContext";

import { useExtractQueryParam } from "hooks/useExtractQueryParam";

import { useLoadingEffectRouter } from "helpers/utils/routing";

import Button from "components/buttons/Button";
import { ErrorCard } from "components/cards/ErrorCard";
import GoogleIcon from "components/images/GoogleIcon";
import MicrosoftIcon from "components/images/MicrosoftIcon";
import HeadWithTags from "components/meta/HeadWithTags";

const formatSSOError = (errorCode: string | string[] | null) => {
  if (errorCode === "organisation-not-registered")
    return "Your organisation isn't currently part of Pinwheel.";

  return "Sorry, we weren't able to log you in";
};

const LOGIN_PROVIDERS: { logo: ReactNode; name: string; code: string }[] = [
  { name: "Microsoft", code: "microsoft-graph", logo: <MicrosoftIcon /> },
  { name: "Google", code: "google-oauth2", logo: <GoogleIcon /> },
];

export const getStaticProps: GetStaticProps = async function () {
  return { props: {} };
};

const Login = () => {
  // Router
  const router = useLoadingEffectRouter();

  // Variables
  const {
    themeDarkColor,
    themeHyphen,
    themePrimaryColor,
    setNavbarStyle,
    setFooterStyle,
  } = useUIContext();
  const { isAuthenticated } = useUserContext();

  useEffect(() => {
    if (isAuthenticated) router("/account");
  }, [isAuthenticated, router]);

  useEffect(() => {
    setNavbarStyle({
      navbarColor: "navy",
      navbarMobileHamburgerIconColor: "white",
      navbarTabletHamburgerIconColor: "navy",
      navbarLogo: "primary",
    });
    setFooterStyle({
      showShortFooter: true,
      footerBackgroundColor: themeDarkColor,
    });
  }, []);

  const [ssoError] = useExtractQueryParam("sso-error");

  return (
    <>
      <HeadWithTags
        title="Account Login"
        description="Login to your Pinwheel account."
      />
      <main>
        <div
          className={
            `grid grid-cols-12 min-h-screen ` +
            `bg-${themeHyphen}-bg-img-no-text ` +
            `bg-cover bg-no-repeat bg-center`
          }
        >
          <div
            className={
              `login-sidebar relative py-20 sm:py-0 ` +
              `bg-${themeHyphen}-bg-img-no-text-mobile ` +
              `bg-cover bg-no-repeat ` +
              `sm:bg-none col-span-12 sm:col-span-4 flex items-center ` +
              `justify-center px-6 sm:px-0`
            }
          >
            <h1
              className={
                `block sm:hidden font-black text-white text-heading2 ` +
                `sm:text-heading1`
              }
            >
              Welcome back
            </h1>
          </div>
          <div
            className={
              `login-main-content col-span-12 sm:col-span-8 ` +
              `px-6 sm:px-10 xl:px-52 py-8 sm:pt-44 sm:pb-36 space-y-16 ` +
              `rounded-t-2xl sm:rounded-t-none bg-white ` +
              `flex flex-col`
            }
          >
            <div
              className={
                `flex flex-col items-center justify-center flex-1 ` +
                `max-w-md basis-full w-full mx-auto space-y-11 md:space-y-14`
              }
            >
              <div>
                <h1
                  className={
                    `hidden sm:block font-black text-heading2 ` +
                    `sm:text-heading1 text-center `
                  }
                >
                  Welcome back
                </h1>
                <p className="text-body21 text-center">
                  Please login to continue
                </p>
              </div>
              <div className="space-y-8 w-full">
                {LOGIN_PROVIDERS.map(({ name, code, logo }) => (
                  <Link
                    key={name}
                    href={encodeURI(
                      `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/auth/sso/begin/${code}`
                    )}
                  >
                    <a
                      className={
                        `relative focus:outline-none py-2.5 px-6 ` +
                        `rounded-full font-medium text-navy text-body16 ` +
                        `border-metal-grey/60 border-2 ` +
                        `inline-flex w-full items-center justify-center select-none`
                      }
                    >
                      <span className="inline-flex items-center h-3.5">
                        {logo}
                      </span>
                      <span className="pl-4">Login with {name}</span>
                    </a>
                  </Link>
                ))}
                {!!ssoError && (
                  <ErrorCard>{formatSSOError(ssoError)}</ErrorCard>
                )}
                <div className="relative text-center">
                  <div className="absolute top-1/2 w-full bg-metal-grey h-px opacity-60" />
                  <div className="relative bg-white px-2 inline-block">
                    <span className="text-metal-grey font-medium opacity-60">
                      OR
                    </span>
                  </div>
                </div>
                <Button
                  text="Log in with Email and Password"
                  borderColor="metal-grey/60"
                  onClick={async () => await router("/login/email")}
                  addClasses="select-none"
                  isFullWidth={true}
                />
              </div>
            </div>
            <div
              className={
                `flex justify-end text-navy items-center space-x-1 ` +
                `text-body16 cursor-pointer pt-4`
              }
            >
              <Link href="/create-personal-account">
                <a>
                  <p>
                    Don&apos;t have an account?
                    <span className="font-medium pl-2">Sign up</span>
                  </p>
                </a>
              </Link>
              <div className="flex w-5">
                <IconArrowRight color={themePrimaryColor} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
