type ErrorCardProps = {
  children: React.ReactNode;
};

export const ErrorCard = ({ children }: ErrorCardProps) => {
  return (
    <div className="bg-error-red-light border-error-red border-2 rounded-lg p-5 text-body21 font-medium text-navy">
      {children}
    </div>
  );
};
